@import "../../scss/variables";
@import "../../scss/global";
@import "../../scss/bootstrap";

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 540px !important;
  @include media-breakpoint-up(md) {
    max-width: 720px !important;
  }
  @include media-breakpoint-up(lg) {
    max-width: 960px !important;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1213px !important;
  }
  @include media-breakpoint-up(xxl) {
    max-width: 1214px !important;
  }
}

.date-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  height: 25px;
  width: 25px;
  margin: 0 10px;
  cursor: pointer;
}

.mb-0 {
  margin-bottom: 0;
}

.martop-1 {
  margin-top: 1px;
}

.tag {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

.tech-headline-tag {
  display: inline-block;
  background: $primary;
  color: $white;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  padding: $spacer ($spacer * 2);
  text-transform: uppercase;
  font-size: $font-size-sm;
}

.page {
  position: relative;
  h4 {
    font-family: CalibriBold;
  }
}

.article-grid {
  background: none;
  column-gap: $spacer;
  display: grid;
  padding-bottom: 100px;
  padding-top: 0;
  position: relative;
  row-gap: $spacer;
  margin: 0 auto;
  .post-actions {
    position: absolute !important;
    bottom: $spacer;
    left: $spacer;
    right: $spacer;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  &.alt-grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr;
      @include make-col(10);
    }
  }
  article {
    position: relative;
    // padding-bottom: $spacer * 1.5;
    background: $white;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }
  }
}

/**
  * Additional News Section
  */
.additional-news {
  background: $white;
  padding: $spacer * 2 0;
  position: relative;
  z-index: 3;
  @include media-breakpoint-up(lg) {
    padding: 100px 0;
  }
  .tag {
    font-family: CalibriBold;
    font-size: 0.875em;
  }
}

.tabs {
  display: grid;
  column-gap: $spacer;
  row-gap: $spacer;
  margin-bottom: 100px;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.tabContent {
  @include media-breakpoint-up(xl) {
    position: relative;
    transition: height 0.5s ease-in-out;
    .slide {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  .category-tab {
    margin-bottom: $spacer * 2;
  }
}
