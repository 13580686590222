@import "../../scss/variables";
@import "../../scss/global";
@import "../../scss/bootstrap";

.category-tab {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: $white;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &[style] {
    background-size: cover !important;
  }

  .mask {
    display: none;
  }

  h3 {
    text-transform: uppercase;
    font-size: $h5-font-size;
    text-align: center;
    z-index: 1;
    position: relative;
    font-family: CalibriBold;
  }

  .icon {
    margin-bottom: $spacer;
    z-index: 1;
    position: relative;
  }

  &.selected {
    height: 230px;
    .icon,
    h3 {
      position: relative;
      top: -15px;
    }
    .mask {
      @include media-breakpoint-up(lg) {
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &.selected:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background: $primary;
  }
}
