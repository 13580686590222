@import "../../scss/variables";
@import "../../scss/global";
@import "../../scss/bootstrap";

// .post-enter-active {
//     opacity: 1;
//     transition: all 0.5s ease 0.5s;
//   }

//   .post-exit-active {
//     opacity: 0;
//     height: 0px;
//     transform: translateY(20px);
//     transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1)
//   }

.slide-show-height {
  height: 360px;
}
.carousel-item {
  opacity: 1;
  transition: all 0.5s ease 0.5s;
  padding-bottom: 20px;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.btn-tag {
  background: #fff;
  color: #495057;
  display: inline-block;
  font-size: 0.875em;
  font-weight: 700;
  padding: 0.5rem 2rem;
  text-transform: uppercase;
}
.slide-show {
  position: relative;
  color: #fff;
}
.description {
  margin-bottom: 2rem;
  font-family: Calibrilight;

}
.title {
  font-family: CalibriBold;
 font-size: calc(1.375rem + 1.5vw);
}
