@import "../../scss/variables";
@import "../../scss/global";
@import "../../scss/bootstrap";
.post-slider {
  position: relative;
  z-index: 100;
  section {
    display: flex;
    column-gap: $spacer * 4;
    flex-direction: column;
    margin: 0 auto;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    @include media-breakpoint-up(xxl) {
      @include make-col(10);
    }

    > div {
      flex: 1;
    }
  }
  .slidergraybg {
    // background: $gray;
    border-radius: 10px;
    @media only screen and (max-width: 580px) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .slidergraybg .post.standard {
    background: #dee2e8;
  }

  .slidergraybg .border-line {
    margin-top: 40px;
  }
  .even-slider #graybg-img {
    padding-right: 0px;
    @media only screen and (max-width: 580px) {
      padding-left: 12px;
    }
  }
  .odd-slider #graybg-img {
    padding-left: 1px;
  }

  .even-slider {
    .post.standard,
    .gray-padding {
      padding-left: 0px;
      @media only screen and (max-width: 580px) {
        padding-left: 0px !important;
      }
      @media only screen and (max-width: 767px) {
        padding-left: 10px;
      }
    }
    @media only screen and (max-width: 580px) {
      padding-left: 24px;
      padding-right: 24px;
    }
    // padding-top:0.5rem;
  }
  .odd-slider {
    .post.standard,
    .gray-padding {
      padding-right: 0px;
    }
    @media only screen and (max-width: 580px) {
      padding-left: 24px;
      padding-right: 24px;
      // padding-top:0.5rem;
    }
  }

  .slidergraybg .post.standard {
    background: $gray;
    padding-bottom: 0px;
    padding-top: 22px;

    .content {
      //padding: 1rem 2rem 2rem;

      padding-left: 22px;
      padding-right: 30px;
      padding-bottom: 22px;
    }
    .perspective-badge-development {
      text-align: center;
      height: 10px;
      margin-top: -35px;
    }

    .perspective-badge-development span {
      background-color: rgb(23, 92, 183);
      padding: 4px 30px 4px 30px;
      border-radius: 38px;
      width: 15%;
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  .slidergraybg .gray-padding {
    padding: 0;
  }

  nav {
    padding: 2rem;
    color: #fff;
    margin-bottom: 40px;
    align-items: center;
  }

  nav.dots {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    bottom: 0;
    margin-bottom: 0px;
    margin-top: 50px;
    justify-content: center;
    width: 100%;
    a {
      background: $gray-400;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      display: block;
      margin: 0 calc($spacer / 2);
      &.active {
        background: $primary;
      }
    }
  }
}

.load-more {
  position: relative;
  width: 165px;
  height: 40px;
  border: 1px solid #dee2e8;
  border-radius: 4px;
  opacity: 1;
  top: 0;
  margin: 10px 0;
  left: 50%;
  transform: translateX(-50%);
}

.no-posts {
  padding-bottom: $spacer * 2;
  @include media-breakpoint-up(md) {
    min-height: 100px;
  }
  text-align: center;
  a {
    text-decoration: none;
  }
}

.left > div,
.right > div {
  border-bottom: 2px solid $gray-200;
  padding-bottom: $spacer * 2;
  margin-bottom: $spacer * 2;
}
