@import "../../scss/variables";
@import "../../scss/global";
@import "../../scss/bootstrap";

  .feedbackSection {
    background: $blue url("../../images/exports/banner_1.png");
    background-position: center center;
    background-size: cover;
    color: $white;
    padding: $spacer * 6 0;
    position: relative;
  }
  
  .feedback {
    padding: 0 $spacer;
  
    text-align: center;
    & > div:first-child {
      margin-bottom: $spacer * 3;
    }
  
    @include media-breakpoint-up(xl) {
      text-align: left;
      display: flex;
      align-items: center;
      padding: 0 120px; // Fix this by using gridw
  
      & > div:first-child {
        flex: 1;
        display: grid;
        margin-bottom: 0;
        grid-template-columns: min-content max-content;
        column-gap: $spacer * 2;
      }
  
      & > div:last-child {
        flex: 0;
      }
    }
  }
  
  .chat-icon {
    margin-bottom: $spacer * 0.4;
    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
      position: relative;
      // top: 10px;
    }
  }


  