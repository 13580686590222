
@import "../scss/variables";
@import "../scss/global";
@import "../scss/bootstrap";
.modalsection button {
  // background: darkgray;
  border: 0;
  position: fixed;
  right: 117px;
  top: 20px;
  z-index: 100;
  position: fixed;
    /* transform: translate(26px, -35px); */
    // right: 117px;
    // top: 8px;
    background: transparent;

    @media only screen and (max-width: 991) {
      &:before {
        content: "";
        background: hsla(0,0%,100%,.8);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: block;
        position: fixed;
        right: 12px;
        top: 11px;
        z-index: 1;
      }
    }
  @media only screen and (max-width: 480px) {
    
    right: 9px;
    // margin-right: 98px;
    top: 14px;
}  



  &:before {
    // content: "";
    // background: hsla(0,0%,100%,.8);
    // border-radius: 50%;
    // width: 50px;
    // height: 50px;
    // display: block;
    // position: fixed;
    // right: 12px;
    // top: 11px;
    z-index: 1;
    // content: "";
    // width: 40px;
    // height: 40px;
    // right: 10px;
    // top: 10px;
    // background: rgba(255, 255, 255, 0.8);
    // border-radius: 50%;
    // width: 50px;
    // height: 50px;
    // display: block;
    // position: fixed;
    // right: 12px;
    // top: 11px;
    // z-index: 1;
    // content: "";
    // background: rgba(255, 255, 255, 0.8);
    // border-radius: 50%;
    // width: 30px;
    // height: 30px;
    // display: block;
    // position: fixed;
    // right: 28px;
    // top: 19px;
    // z-index: 1;
    @include media-breakpoint-up(lg) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
    //  width: 25px;
    //   height: 25px;
    //   right: 12px;
    //   top: 15px;

  }
  }
  svg {
    position: relative;
    z-index: 2;
  }
  
  @include media-breakpoint-up(lg) {
    right: 0px;
    top: 0px;
    z-index: 100;
    position: absolute;
    svg {
      stroke: #f9f5f5;
      background: transparent;
      // border:1px solid darkgray;
    }
  }
}

@media (min-width: 992px)
{
  .modalsection button {
      right: 0;
      top: 0;
      position: absolute;
  }
}
@media (max-width: 991px)
{
  .modalsection button{
    right: 0px;
    top: 10px;
    z-index: 100;
    position: absolute;
  }
}

.modalParent {
  background-color: rgba(12, 12, 12, 0.315);
  height: 100vh;
  width: 100%;
  cursor: pointer;
  padding: 40px 0;
  overflow: auto;
  position: fixed;
  @media only screen and (max-width: 991px) {
    padding:0px;
  }
}

.ab {
    padding: 2rem;
    background: white;
    @media only screen and (max-width: 991px) {
      padding: 0px;
    }
}


.modalsection {
  // background-color: white;
  // height: 100vh;
  // width: 80%;
  // margin: 0 auto;
  // overflow-y:scroll;
  // padding: $spacer * 4 $spacer * 2 $spacer * 4;
  // box-shadow: 0px 0px 11px 0px rgba(128,128,128,0.82);
  // -webkit-box-shadow: 0px 0px 11px 0px rgba(128,128,128,0.82);
  // -moz-box-shadow: 0px 0px 11px 0px rgba(128,128,128,0.82);
    // background-color: white;
    //height: 100%;
    max-width: 1045px;
    margin: 0 auto;
    //overflow-y: scroll;
    // padding: 2rem 2rem 4rem;
    // box-shadow: 0px 0px 11px 0px rgb(128 128 128 / 82%);
    // -webkit-box-shadow: 0px 0px 11px 0px rgb(128 128 128 / 82%);
    // -moz-box-shadow: 0px 0px 11px 0px rgba(128, 128, 128, 0.82);
    //position: fixed;
    top: 31px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    position: relative;
    padding: 2rem;
    @media only screen and (max-width: 991px) {
      padding: 3rem 1rem 1rem 1rem;
      background: white;
      top: 0;
    }
  @include media-breakpoint-up(lg) {
    // padding: $spacer * 2 $spacer * 2 $spacer * 4;
  }
}

.modalsection .post-content {
  @include media-breakpoint-up(lg) {
    padding: 0 $spacer * 2;
  }
}
.modalsection span[style*="font-size: 10px;"] {
  // Hide link to article
  display: block;
  font-size: $font-size-base !important;
  margin-bottom: $spacer;
}

.modalsection .feature-image {
 // margin-bottom: $spacer * 2;
  margin-bottom:25px;
  width: 100%;
}

.modalsection .post-actions {
  border-top: 2px solid $gray-200;
  padding-top: $spacer;
  margin-top: $spacer * 2;
}

.modalsection {
  .perspective {
    position: relative;
  }
  
  .perspective .tooltiptext {
    visibility: hidden;
    width: 600px;
    background-color: #175cb7;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    top: -110px;
    left: 50%;
    /* padding: 0.5rem; */
    transform: translateX(-50%);
    z-index: 1;
  }
  
  .perspective .tooltiptext::after {
    content: "";
    position: absolute;
    top: 98%;
    left: 50%;
    margin-left: -12px;
    border-width: 10px;
    border-style: solid;
    border-color: #175cb7 transparent transparent transparent;
  }
  
  .perspective:hover .tooltiptext {
    visibility: visible;
  }
  
  blockquote {
    font-weight: $font-weight-bold;
    font-style: normal;
    position: relative;
    color: $black;
    margin-top: $spacer * 3;
  }
  
  .perspective blockquote {
    color: $primary;
    background: $gray-100;
    padding: $spacer * 2;
    &:before {
      position: absolute;
      content: "";
      width: 40px;
      height: 40px;
      background: url("../images/icons/perspective_mark.svg") no-repeat;
      left: 50%;
      top: -20px;
      transform: translateX(-50%);
    }
  }
  
  pre {
    display: block;
    background: url("../images/icons/accent.png") 0 0 no-repeat;
    color: $primary;
    font-style: italic;
    text-align: center;
    padding: 20px 60px;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    white-space: pre-wrap; /* Since CSS 2.1 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font-family: $font-family-base;
    font-size: $font-size-base;
    &:before {
      content: "";
      height: 10px;
      display: block;
      background: url("../images/icons/accent.png") top center no-repeat;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    &:after {
      content: "DH&I Perspective";
      display: block;
      font-style: normal;
      font-weight: $font-weight-bold;
      margin-top: 20px;
      text-align: center;
    }
  }
}
