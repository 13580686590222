@import "../../scss/variables";
@import "../../scss/global";
@import "../../scss/bootstrap";

nav {
    color: $white;
    display: flex;
    padding: $spacer;
    flex-direction: column;
    margin-bottom: 30px;
    @include media-breakpoint-up(lg) {
        padding: $spacer * 2;
        flex-direction: row;
        align-items: center;
        margin-bottom: 70px;
    }
    div {
        flex: 1;
        &:first-child {
            margin-bottom: $spacer * 2;
            @include media-breakpoint-up(lg) {

            }
        }
        &:last-child {
        @include media-breakpoint-up(sm) {
            text-align: center;
        }
           @include media-breakpoint-up(lg) {
               text-align: right;
            }
        }
    }
    p {
        display: inline-block;
        margin-bottom: 0;
        max-width: 528px;
        font-style: normal;
        font-family: CalibriLight;
        font-weight: 300;
        i {
            font-weight: 300;
            font-family: CalibriLightItalic;
        }
    
    strong {
        font-weight: 400;
        font-family: Calibri;
    }
    b {
        font-weight: 400;
        font-family: Calibri;
    }
  }
}
