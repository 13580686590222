@import "./scss/variables";
@import "./scss/global";
@import "./scss/bootstrap";
@import "~bootstrap/scss/bootstrap";

#portal-root {
    position: fixed;
    top: 0px !important;
    width: 100%;
    margin: 0 auto;
    z-index: 1000;
    // overflow-y: scroll;
  
    // position: fixed;
    // top: 50px !important;
    // width: 100%;
    // margin: 0 auto;
    // z-index: 100;
    // height: 100%;
    // min-height: 100vh;
    // overflow-y: scroll;
  
  }

  // .wrapper.has-alert {
  //   //top: 60px;
  //   top: 0px;
  //   position: relative;
  
  //   .header-background,
  //   // .fade {
  //   //   // height: 700px + 60px;
  //   // }
  
  //   .fade-mask {
  //     top: 700px + 60px;
  //   }
  // }
  
 .wrapper .alert {
    will-change: auto;
    background: rgb(252, 239, 197);
    display: flex;
    margin-bottom: 0px;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 60px;
    top: 0;
    width: 100%;
    font-weight: $font-weight-bold;
    z-index: 600;
    transition: height 0.25s linear;
  
    >* {
      padding: 0 calc($spacer / 2);
    }
    .title{
      font-size:16px;
      font-family: CalibriBold;
    }
  
    a {
      text-decoration: none;
  
      &:hover {
        text-decoration: underline;
      }
    }
  
    .icon svg path {
      fill: $danger;
    }
  
    &.collapse {
      height: 40px;
    }
  }
  
  #particles-js {
    opacity: 0.2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  // article.graybg.p-2 {
  //   margin-bottom: -100px;
  // }
  .hr {
    margin: 0 auto;
    color: inherit;
    border: 0;
    border-top: 2px solid;
    opacity: 0.25;
    width: 23%;
    padding-bottom: 10px;
  }
  
  .header-background {
    // background-image: url("http://localhost:3000/images/background/header-bg.png");
    // // background: #002c57;
    // background-size: cover;
    // height: 550px !important;
    // position: absolute;
    // top: 0;
    // width: 100%;
    // z-index: 0;
    background: $blue url("./images/exports/banner_1.png");
    background-size: cover;
    height: 700px;
    position: fixed;
    //top: 0;
    width: 100%;
    z-index: 0;
  }
  
  .fade {
    opacity: 0;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    position: fixed;
    top: 0;
    height: 700px;
    left: 0;
    right: 0;
    z-index: 2;
  }
  
  .fade-mask {
    background: $white;
    position: fixed;
    top: 700px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
  }
  
  header {
    z-index: 10;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  .page-content {
    position: relative;
    z-index: 10;
    // background: #F5F6F7;
    // top: 150px;
    //top: 600px;
  }