@import "../../scss/variables";
@import "../../scss/global";
@import "../../scss/bootstrap";

.post-content {
  .video-thumb {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(50%, 10%);
    width: 50%;
    opacity: 0.4;
  }
  h3{
    font-family: CalibriBold;
  }
  .content{
    font-family: CalibriLight;
  }

  button {
    background: none;
    border: 0;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 100;
    &:before {
      content: "";
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: block;
      position: fixed;
      right: 12px;
      top: 11px;
      z-index: 1;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
    svg {
      position: relative;
      z-index: 2;
    }
    @include media-breakpoint-up(lg) {
      position: absolute;
      transform: translate(26px, -35px);
      right: 0;
      top: 0;
      svg {
        stroke: white;
      }
    }
  }
  .wrapper {
    padding: $spacer * 4 $spacer * 2 $spacer * 4;
    @include media-breakpoint-up(lg) {
      padding: $spacer * 2 $spacer * 2 $spacer * 4;
    }
  }

  .post-content {
    @include media-breakpoint-up(lg) {
      padding: 0 $spacer * 2;
    }
  }
  span[style*="font-size: 10px;"] {
    // Hide link to article
    display: block;
    font-size: $font-size-base !important;
    margin-bottom: $spacer;
  }

  .feature-image {
    margin-bottom: $spacer * 2;
    width: 100%;
  }

  .post-actions {
    border-top: 2px solid $gray-200;
    padding-top: $spacer;
    margin-top: $spacer * 2;
  }

  .perspective {
    position: relative;
  }

  .perspective .tooltiptext {
    visibility: hidden;
    width: 350px;
    background-color: #175cb7;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    top: -130px;
    left: 50%;
    /* padding: 0.5rem; */
    transform: translateX(-50%);
    z-index: 1;
    @include media-breakpoint-up(md) {
      width: 600px;
      top: -110px;
    }
  }

  .perspective .tooltiptext::after {
    content: "";
    position: absolute;
    top: 98%;
    left: 50%;
    margin-left: -12px;
    border-width: 10px;
    border-style: solid;
    border-color: #175cb7 transparent transparent transparent;
  }

  .perspective:hover .tooltiptext {
    visibility: visible;
  }

  blockquote {
    font-weight: $font-weight-bold;
    font-style: normal;
    position: relative;
    color: $black;
    margin-top: $spacer * 3;
  }

  .perspective blockquote {
    color: $primary;
    background: $gray-100;
    padding: $spacer * 2;
    &:before {
      position: absolute;
      content: "";
      width: 40px;
      height: 40px;
      background: url("../../images/icons/perspective_mark.svg") no-repeat;
      left: 50%;
      top: -20px;
      transform: translateX(-50%);
    }
  }

  pre {
    display: block;
    background: url("../../images/icons/accent.png") 0 0 no-repeat;
    color: $primary;
    font-style: italic;
    text-align: center;
    padding: 20px 60px;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    white-space: pre-wrap; /* Since CSS 2.1 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font-family: $font-family-base;
    font-size: $font-size-base;
    &:before {
      content: "";
      height: 10px;
      display: block;
      background: url("../../images/icons/accent.png") top center no-repeat;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    &:after {
      content: "DH&I Perspective";
      display: block;
      font-style: normal;
      font-weight: $font-weight-bold;
      margin-top: 20px;
      text-align: center;
    }
  }
}
