$blue: rgb(23,92,183);
$border-radius: 0;
$font-family-sans-serif:      calibri, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-size-base:              1rem;
$h3-font-size:                $font-size-base * 1.4;
$h4-font-size:                $font-size-base * 1.4;
$headings-font-weight: 700;
$font-weight-base: 300;
$container-max-widths: (
  sm: 540px,
  md: 740px,
  lg: 764px,
  xl: 960px, // Adjust grid to match mockup
  // xl: 1213px, // Adjust grid to match mockup
  xxl: 960px
) !default;
$white: #FFF;
$font-weight-bold: 700;
$gray-100:  #F1F1F1;
$gray-200: #e9ecef;
$gray-700: #cecece;
$btn-font-weight: $font-weight-bold;
$small-font-size: 1rem;
$spacer: 1rem;
$gray:#dee2e8;
$whitepaper-badge:#058257