@import "../../scss/variables";
@import "../../scss/global";
@import "../../scss/bootstrap";

.post {
    background: $white;
  }
  
  .video {
    padding-top: 0 !important;
    margin-bottom: 1rem !important;
  }
  .video-thumb {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(50%, 10%);
    width: 50%;
    opacity: 0.4;
  }
  
  .perspective-tooltip {
    visibility: hidden;
    background-color: #fff;
    width: 285px;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 10px 15px;
    position: absolute;
    white-space: normal;
    text-transform: none;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    font-weight: 100;
    font-size: 14px;
    border: 2px solid #175cb7;
  }
  
  @include media-breakpoint-up(sm) {
    .perspective-tooltip {
      width: 400px;
    }
  }
  
  @include media-breakpoint-up(lg) {
    .perspective-tooltip {
      width: 285px;
    }
  }
  
  .perspective-tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -12px;
    border-width: 10px;
    border-style: solid;
    border-color: #175cb7 transparent transparent transparent;
  }
  
  .perspective-badge:hover > .perspective-tooltip {
      visibility: visible;
  }
  
  .perspective-badge {
    background: $primary;
    color: $white;
    display: inline-block;
    padding: $badge-padding-y $badge-padding-x * 3;
    @include font-size($badge-font-size);
    font-weight: $badge-font-weight;
    line-height: 1;
    color: $badge-color;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    text-transform: uppercase;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }
  .perspective-whitepaper-badge {
    background: $whitepaper-badge;
    color: $white;
    display: inline-block;
    padding: $badge-padding-y $badge-padding-x * 3;
    @include font-size($badge-font-size);
    font-weight: $badge-font-weight;
    line-height: 1;
    color: $badge-color;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    text-transform: uppercase;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }
  
  .perspective-badge-border {
    content: "";
    background: $primary;
    height: 4px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transform: translateY(100%);
  }
  .perspective-whitepaper-badge-border {
    content: "";
    background: $whitepaper-badge;
    height: 4px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    transform: translateY(100%);
  }
  
  h3 a {
    color: $black;
    text-decoration: none;
    &:hover {
      color: $link-hover-color;
    }
  }
  
  .feature-image {
    cursor: pointer;
    width: 100%;
    position: relative;
    &[style] {
      background-size: cover;
      background-position: center center;
    }
  }
  
  .post.standard {
    .feature-image {
      padding-top: 56.25%;
      margin-bottom: $spacer * 3;
    }
    p {
      font-family: CalibriLight;
    }
    h3{
      font-family: CalibriBold;
      font-size: calc(1.265rem + .18vw);
    }

  }
  
  .post:not(.card) .feature-image.non-hero {
    .perspective-badge {
      @include media-breakpoint-up(xl) {
        span {
          display: none;
        }
      }
    }
  }
  
  .post.card {
    border:none !important;
    .row {
      display: block;
      height: 100%; // Ensure photo goes all the way to the bottom
      > div {
        flex: auto;
        width: auto;
      }
    }
  
    .content {
      padding: $spacer * 3 $spacer * 2 $spacer * 2;
    }
  
    p {
      hyphens: auto;
      font-family: CalibriLight;
    }
    h3{
      font-family: CalibriBold;
      font-size: calc(1.265rem + .18vw);
    }
  
    .feature-image {
      padding-top: 56.25%;
      height: 100%;
    }
  }
  .noscroll {
    overflow-y:hidden;
    }
  
