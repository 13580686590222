// Move this to global?
@import "./variables";
body
{
font-family: calibri;
}
.clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Animations */

.slide-fade-enter-active {
  transition: all 0.3s ease .5s;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
  {
  transform: translateY(10px);
  opacity: 0;
}

.btn {
  text-transform: uppercase;
  white-space: nowrap;
}

.tag {
  background: $white;
  color: #495057;
  display: inline-block;
  font-size: $small-font-size;
  font-weight: $font-weight-bold;
  padding: calc($spacer / 2) calc($spacer * 2);
  text-transform: uppercase;
  @media only screen and (max-width: 480px) {
    
      padding-right:5px;
      padding-left: 5px;
  }
}