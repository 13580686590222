@import "../../scss/variables";
@import "../../scss/global";
@import "../../scss/bootstrap";

.post-actions {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: grid;
      grid-template-columns: 1fr max-content max-content max-content;
      column-gap: $spacer;
      align-items: center;
    }
  
    li {
      display: block;
    }
  
    .btn-link {
      text-decoration: none;
    }
  
    a svg path {
      fill: $primary !important;
    }
  
    a {
      text-transform: uppercase;
      text-decoration: none;
      font-weight: $font-weight-bold;
      display: inline-block;
      font-family: CalibriBold;
    }
  
    &.light {
      a {
        color: $white !important;
      }
      a:hover {
        color: #000000 !important;
      }
    }
    &.condensed {
      ul {
        grid-template-columns: min-content max-content max-content;
      }
    }
    .share-links {
      position: absolute;
      display: flex;
      padding: 5px;
      width: 120px;
      background: #ffffff !important;
      border-radius: 10px;
      flex-direction: column;
      color: #0063c3;
      z-index: 10;
      box-shadow: 0px 0px 6px 0px rgb(128 128 128 / 82%);
      -webkit-box-shadow: 0px 0px 6px 0px rgb(128 128 128 / 82%);
      -moz-box-shadow: 0px 0px 6px 0px rgba(128, 128, 128, 0.82);
      justify-content: center;
      align-items: flex-start;

      li {
        cursor: pointer;
      }

      a {
        font-size: 14px;
        text-transform: none;
      }
    }
  }