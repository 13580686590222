@import "../../scss/variables";
@import "../../scss/global";
@import "../../scss/bootstrap";

.annotationLayer {
  display: none !important;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}
.react-pdf__Page__textContent span {
  font-size: 1.5rem !important;
}
.total-page {
  // margin-top: 12px;
  font-size: 1rem;
  color: #000000;
  font-weight: 400;
}
.react-pdf__Page {
  // height: 100vh !important;
  // overflow: auto !important;
  background-color: #ffffff;
  overflow: hidden;
}
.next {
  position: relative;
  text-align: left;
}
.prev {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 20px;
}

.prev div button {
  position: relative;
  margin: 0 15px;
}
.pdf-modal {
  background-color: #ffffff;
  //padding: 10px;
}

.tabButton {
  display: flex;
  margin-bottom: 5px;
  button {
    position: relative;
    &.active {
      background-color: #175cb7;
      color: #ffffff;
    }
  }
}
.quarterly-perspective {
  background: #ffffff;
  border-top: 2px solid $gray-200;
  padding: ($spacer * 2) 0;
  text-align: center;
  position: relative;
  @include media-breakpoint-up(lg) {
    padding: 100px 0;
  }
}
.my-perspective {
  // background: url("../../images/header-bg.jpg");
  background: blue($color: #000000);
  border-top: 2px solid $gray-200;
  padding: ($spacer * 2) 0;
  text-align: center;
  @include media-breakpoint-up(lg) {
    padding: 100px 0;
  }
}
