@import "../../scss/variables";
@import "../../scss/global";
@import "../../scss/bootstrap";

footer {
    color: $white;
    display: flex;
    padding: $spacer * 2 $spacer;
    flex-direction: column;
    background: $gray-100;
    position: relative;
    text-align: center;
    z-index: 100;
  
    > :nth-child(2) {
      color: $gray-600;
      padding: $spacer 0;
    }
    @include media-breakpoint-up(lg) {
      padding: $spacer * 2;
      flex-direction: row;
      align-items: center;
      > :nth-child(2) {
        padding: 0;
        text-align: center;
        flex: 1;
        text-transform: uppercase;
      }
    }
  }